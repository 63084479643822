import React from 'react'

const Tokenomics = () => {

  return (
    <div className='tokenomics'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12 py-5'>
            <div className='h1 px-3'>Tokenomics</div>
            <div className='p-3 text-justify'>
             Welcome to bCity, a GameFi project that is deployed run on the Polygon blockchain!
            </div>
            <div className='h3 px-3'>How Does It Work?</div>
            <div className='p-3 text-justify'>
              The goal of the game is to collect the most honey ($bHNY). You do this by minting honey bees who collect honey for you every day. Season 1 will kick off with 7 types of possible honey bee mints. These bees vary in rarity and how much honey they collect.
            </div>
            <div className='p-3 text-justify'>
              The Honey Bank (liquidity pool) will receive 70% of Matic from mint purchase and the treasury (developers wallet) will receive 30% of mint purchases. The Honey Bank will also receive 100% of all royalties to continuously fund the LP.
            </div>
            <div className='p-3 text-justify'>
            Once your bees begin collecting $bHNY, you will be able to swap $bHNY for $Matic at the Honey Bank.
            </div>
           <div className='p-3 text-justify'>
            Pollen Boosts will be announced at random times in the discord server. These boosts are intended to multiply the amount of $Matic you receive for each $bHNY you sell. This is achieved by increase the $bHNY to $MATIC ratio at the Honey Bank for a very short amount of time.
           </div>
            <div className='p-3 text-justify'>
            Pollen Boosts will be announced at random times in the discord server. These boosts are intended to multiply the amount of $Matic you receive for each $bHNY you sell. This is achieved by increase the $bHNY to $MATIC ratio at the Honey Bank for a very short amount of time.
          </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Tokenomics