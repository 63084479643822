import React, { useEffect, useState } from 'react';
import secondsToHms from './../../utils/secondsToHms'

const TimeCounter = (props) => {
    const [seconds, setSeconds] = useState(0);
    const [intervalId, setIntervalId] = useState(null);
  
    useEffect(() => {
      setIntervalId(setInterval(() => {
        const diff = parseInt(new Date().getTime() / 1000 - props.time);
        setSeconds(diff);
      }, 1000));
      
      return () => {
        clearInterval(intervalId);
      }
    }, [props.time]);
  
    useEffect(() => {
      if (seconds >= 24 * 60 * 60) {
        clearInterval(intervalId);
      }
    }, [seconds]);
  
    return (
      (seconds >= 24 * 60 * 60) ?
        <><em>Honey I'm Home!</em></>
      :
        <>{secondsToHms(seconds)}</>
    );
  };
  
  export default TimeCounter;